import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { jwtDecode } from "jwt-decode"
import moment from 'moment'
import miniToastr from 'mini-toastr'
import { createStore } from 'vuex'

miniToastr.init()

export default createStore({
  state: {
    assistVersion: '1.1.0',
    status: '',
    statusDescription: '',
    refreshStatus: '',
    taskStatus: '',
    overviewStatus: '',
    messagesStatus: '',
    newsStatus: '',
    timelineStatus: '',
    personalisationStatus: '',
    contactStatus: '',
    settingsStatus: '',
    isTaskPolling: false,
    isAuthPolling: false,
    accessToken: localStorage.getItem('accessToken') || '',
    idToken: localStorage.getItem('idToken') || '',
    assistFrame: localStorage.getItem('assistFrame') || '',
    tasks: [],
    overview: [],
    messages: [],
    news: [],
    timeline: [],
    personalisation: [],
    contact: [],
    settings: [],
    user: [],
    adhoc: [],
    displayedMessage: null,
    loginRequested: false,
    loginShow: false,
    loginReason: null,
    verifyStatusShow: false,
    maintShow: false,
    offlineShow: false,
    // coreui
    asideVisible: false,
    sidebarVisible: '',
    sidebarUnfoldable: false,
    theme: 'default',
    pollOverview: null,
    pollContact: null,
    pollTasks: null,
    pollMessages: null,
    pollTimeline: null,
    storedMasterItemsPerPage: 10,
    storedMasterDesign: 1
  },
  mutations: {
    initialise_store(state) {
      // Check if the ID exists
      if(localStorage.getItem('store')) {
        // Replace the state object with the stored item
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem('store')))
        );
      }
    },
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, token) {
      state.status = 'success'
      state.statusDescription = 'success'
      state.accessToken = token
      //state.idToken = authResult.idToken
      localStorage.setItem('accessToken', token)
      //localStorage.setItem('idToken', authResult.idToken)
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
      // see if we need to pull initial settings from id payload for homepage
      // if (this.getters.idCustomPayload.settings.assistHomepage) {
      //   state.assistHomepage = this.getters.idCustomPayload.settings.assistHomepage
      //   localStorage.setItem('assistHomepage', this.getters.idCustomPayload.settings.assistHomepage)
      // }

      this.dispatch('loadUser', true)
      //this.dispatch('startPollTasks')
    },
    auth_error(state, err) {
      state.status = 'error'
      if (err) {
        state.statusDescription = err
      }
    },
    logout(state) {
      state.loginRequested = true
      state.status = ''
      delete axios.defaults.headers.common['Authorization']
      state.accessToken = ''
      state.tasks = []
      state.overview = []
      window.localStorage.clear()
      if (this.getters.isMacFrame) {
        window.webkit.messageHandlers.logoutFrame.postMessage({})
      }
      console.log('[Assist] Logged out!')
    },
    tasks_poll_start(state) {
      state.isTaskPolling = true
    },
    tasks_poll_end(state) {
      state.isTaskPolling = false
    },
    tasks_request(state) {
      state.taskStatus = 'loading'
    },
    tasks_success(state, tasks) {
      state.taskStatus = 'success'
      state.tasks = tasks
    },
    overview_request(state) {
      state.overviewStatus = 'loading'
    },
    overview_success(state, overview) {
      state.overviewStatus = 'success'
      state.overview = overview
    },
    messages_request(state) {
      state.messagesStatus = 'loading'
    },
    messages_success(state, messages) {
      state.messagesStatus = 'success'
      state.messages = messages
    },
    news_request(state) {
      state.newsStatus = 'loading'
    },
    news_success(state, news) {
      state.newsStatus = 'success'
      state.news = news
    },
    timeline_request(state) {
      state.timelineStatus = 'loading'
    },
    timeline_success(state, timeline) {
      state.timelineStatus = 'success'
      state.timeline = timeline
    },
    personalisation_request(state) {
      state.personalisationStatus = 'loading'
    },
    personalisation_success(state, personalisation) {
      state.personalisationStatus = 'success'
      state.personalisation = personalisation
    },
    contact_request(state) {
      state.contactStatus = 'loading'
    },
    contact_success(state, contact) {
      state.contactStatus = 'success'
      state.contact = contact
    },
    user_request(state) {
      state.userStatus = 'loading'
    },
    user_success(state, user) {
      state.userStatus = 'success'
      state.user = user
    },
    settings_request(state) {
      state.settingsStatus = 'loading'
    },
    settings_success(state, settings) {
      state.settingsStatus = 'success'
      state.settings = settings
    },
    ping_request(state) {
      state.loginRequested = true
    },
    toggleAside(state) {
      state.asideVisible = !state.asideVisible
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleTheme(state, payload) {
      state.theme = payload.value
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    set(state, [variable, value]) {
      state[variable] = value
    },
    toggle(state, variable) {
      state[variable] = !state[variable]
    },
    show_message(state, message) {
      state.displayedMessage = message
    },
    hide_message(state) {
      state.displayedMessage = null
    },
    set_setting(state, [variable, value]) {
      state.settings[variable] = value
    },
  },
  actions: {
    setSetting({ commit }, params) {
      this.commit('set_setting', [params.key, params.value])
    },
    promptLogin({ commit }, reason) {
      return new Promise((resolve, reject) => {
        this.commit('set', ['loginShow', true])
        this.commit('set', ['loginReason', reason])
      })
    },
    login({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        if (this.getters.isMacFrame) {
          var app_slug = 'assist-macos'
        } else {
          var app_slug = process.env.VUE_APP_ID_SLUG
        }
        if (params && params.path && params.connection) {
          window.location.href =
            process.env.VUE_APP_API_URL +
            '/auth/login/' +
            app_slug +
            '?post_redirect=' +
            params.path +
            '&provider=' +
            params.connection
        } else if (params && params.connection) {
          window.location.href =
            process.env.VUE_APP_API_URL +
            '/auth/login/' +
            app_slug +
            '?provider=' +
            params.connection
        } else if (params && params.path) {
          window.location.href =
            process.env.VUE_APP_API_URL +
            '/auth/login/' +
            app_slug +
            '?post_redirect=' +
            params.path
        } else {
          window.location.href =
            process.env.VUE_APP_API_URL + '/auth/login/' + app_slug
        }
      })
    },
    ping({ commit }, path) {
      return new Promise((resolve, reject) => {
        console.info('[Assist] Auth ping requested.')
        commit('ping_request')
        window.location.href =
          process.env.VUE_APP_API_URL +
          '/auth/login/' +
          process.env.VUE_APP_ID_SLUG

        // if (err) {
        //   console.log(err)
        //   this.dispatch("logout", 'silentfail').then(() => {
        //     window.location.replace("/auth/login");
        //   });
        //   reject(err)
        // } else {
        //   amplitude.getInstance().logEvent('UserSilentAuth');
        //   commit('auth_success', result)
        //   //console.log('updated token to '+result.accessToken)
        //   resolve(result)
        // }
        resolve()
      })
    },
    callback({ commit }, token) {
      return new Promise((resolve, reject) => {
        if (!token) {
          commit('auth_error')
        } else {
          try {
            let dt = jwtDecode(token)
            const now = Date.now().valueOf() / 1000
            if (!dt) {
              commit('auth_error', 'Invalid token')
            } else if (typeof dt.exp !== 'undefined' && dt.exp < now) {
              commit('auth_error', 'Token expired')
            } else if (typeof dt.nbf !== 'undefined' && dt.nbf > now) {
              commit('auth_error', 'Token not yet valid')
            }
            commit('auth_success', token)
            console.log(
              'Access token has been updated: ' + this.getters.tokenJTI,
            )
            resolve(token)
          } catch (err) {
            commit('auth_error', err)
            reject(err)
          }
        }
      })
    },
    logout({ commit }, reason) {
      return new Promise((resolve, reject) => {
        console.log('[Assist] Being asked to logout because ' + reason)
        this.dispatch('endPollTasks')
        commit('logout')
        window.location.href =
          process.env.VUE_APP_API_URL +
          '/auth/logout/' +
          process.env.VUE_APP_ID_SLUG
        resolve()
      })
    },
    startPollTasks({ commit }) {
      commit('tasks_poll_start')
      this.state.pollContact = setInterval(() => {
        this.dispatch('loadContact')
      }, Math.floor(Math.random() * 40000) + 100000) // around 2 minutes
      if (this.getters.isLoggedIn) {
        this.state.pollOverview = setInterval(() => {
          this.dispatch('loadOverview')
        }, Math.floor(Math.random() * 40000) + 240000) // around 4 minutes
        this.state.pollTasks = setInterval(() => {
          this.dispatch('loadTasks')
        }, Math.floor(Math.random() * 40000) + 240000) // around 4 minutes
        this.state.pollMessages = setInterval(() => {
          this.dispatch('loadMessages')
        }, Math.floor(Math.random() * 40000) + 100000) // around 2 minutes
        this.state.pollTimeline = setInterval(() => {
          this.dispatch('loadTimeline')
        }, Math.floor(Math.random() * 40000) + 240000) // around 4 minutes
      }
    },
    endPollTasks({ commit }) {
      commit('tasks_poll_end')
      clearInterval(this.state.pollOverview)
      clearInterval(this.state.pollTasks)
      clearInterval(this.state.pollMessages)
      clearInterval(this.state.pollTimeline)
      clearInterval(this.state.pollContact)
    },
    installAdhocPayload({ commit }, payload) {
      return new Promise((resolve, reject) => {
        if (payload.user) {
          commit('user_success', payload.user)
        }
        resolve()
      })
    },
    loadTasks({ commit }) {
      if (this.getters.isLoggedIn) {
        return new Promise((resolve, reject) => {
          commit('tasks_request')
          axios
            .get(process.env.VUE_APP_API_URL + '/api/v2/assist/tasks', {
              needsAuth: true,
            })
            .then((resp) => {
              if (resp && resp.data && resp.data.data) {
                commit('tasks_success', resp.data.data)
                resolve(resp)
              }
            })
        })
      }
    },
    loadOverview({ commit }) {
      if (this.getters.isLoggedIn) {
        return new Promise((resolve, reject) => {
          commit('overview_request')
          axios
            .get(process.env.VUE_APP_API_URL + '/api/v2/assist/overview', {
              needsAuth: true,
            })
            .then((resp) => {
              if (resp && resp.data.overview) {
                commit('overview_success', resp.data.overview)
                resolve(resp)
              }
            })
        })
      }
    },
    loadMessages({ commit }) {
      if (this.getters.isLoggedIn) {
        return new Promise((resolve, reject) => {
          commit('messages_request')
          axios
            .get(process.env.VUE_APP_API_URL + '/api/v2/assist/messages', {
              needsAuth: true,
            })
            .then((resp) => {
              if (resp && resp.data && resp.data.data) {
                commit('messages_success', resp.data.data)
                resolve(resp)
              }
            })
        })
      }
    },
    loadNews({ commit }) {
      if (this.getters.isLoggedIn) {
        return new Promise((resolve, reject) => {
          commit('news_request')
          axios
            .get(process.env.VUE_APP_API_URL + '/api/v2/assist/news?limit=3', {
              needsAuth: true,
            })
            .then((resp) => {
              if (resp && resp.data && resp.data.data) {
                commit('news_success', resp.data.data)
                resolve(resp)
              }
            })
        })
      }
    },
    loadTimeline({ commit }) {
      if (this.getters.isLoggedIn) {
        return new Promise((resolve, reject) => {
          commit('timeline_request')
          axios
            .get(
              process.env.VUE_APP_API_URL + '/api/v2/assist/timeline?limit=7',
              { needsAuth: true },
            )
            .then((resp) => {
              if (resp && resp.data && resp.data.data) {
                commit('timeline_success', resp.data.data)
                resolve(resp)
              }
            })
        })
      }
    },
    loadUser({ commit }, initialLogin = false) {
      return new Promise((resolve, reject) => {
        commit('user_request')
        axios
          .get(process.env.VUE_APP_API_URL + '/api/v2/assist/user', {
            needsAuth: true,
          })
          .then((resp) => {
            if (resp && resp.data && resp.data.user) {
              console.log(resp)
              commit('user_success', resp.data.user)
            }
            resolve(resp)
          })
      })
    },
    loadContact({ commit }) {
      return new Promise((resolve, reject) => {
        commit('contact_request')
        setTimeout(() => {
          axios
            .get(process.env.VUE_APP_API_URL + '/api/v2/assist/contact', {
              needsAuth: false,
            })
            .then((resp) => {
              if (resp.data && resp.data.contact) {
                commit('contact_success', resp.data.contact)
                resolve(resp)
              }
            })
        }, 100)
      })
    },
    loadStatus({ commit }) {
      return new Promise((resolve, reject) => {
          axios.get(process.env.VUE_APP_API_URL + '/api/v2/assist/status', {
            timeout: 10000
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            if (error.code === 'ECONNABORTED') {
              console.log('Request timed out');
            } else {
              console.log(error.message);
            }
          })
      })
    },
    showMessage({ commit }, message) {
      commit('show_message', message)
    },
    hideMessage({ commit }) {
      commit('hide_message')
    },
    enableMaintenance({ commit }) {
      commit('set', ['maintShow', true])
      commit('tasks_poll_end')
      commit('auth_poll_end')
    },
    enableOffline({ commit }) {
      commit('set', ['offlineShow', true])
      commit('tasks_poll_end')
      commit('auth_poll_end')
    },
  },
  getters: {
    assistVersion: (state) => state.assistVersion,
    isLoggedIn: (state) => !!state.accessToken,
    isLoggingIn: (state) => state.status == 'loading',
    isLoginError: (state) => state.status == 'error',
    isRequestingPing: (state) => state.loginRequested,
    isTasksLoaded: (state) => state.taskStatus == 'success',
    // stored and initialised values
    storedMasterItemsPerPage: (state) => state.storedMasterItemsPerPage,
    storedMasterDesign: (state) => state.storedMasterDesign,
    isOpenTickets: (state, getters) =>
      state.overview &&
      state.overview.support_tickets &&
      state.overview.support_tickets.open &&
      state.overview.support_tickets.open > 0,
    isPendingTickets: (state, getters) =>
      state.overview &&
      state.overview.support_tickets &&
      state.overview.support_tickets.pending &&
      state.overview.support_tickets.pending > 0,
    isWaitingTickets: (state, getters) =>
      state.overview &&
      state.overview.support_tickets &&
      state.overview.support_tickets.waiting &&
      state.overview.support_tickets.waiting > 0,
    isExternalTickets: (state, getters) =>
      state.overview &&
      state.overview.support_tickets &&
      state.overview.support_tickets.external &&
      state.overview.support_tickets.external > 0,
    isOpenRepairs: (state, getters) =>
      state.overview &&
      state.overview.repairs &&
      state.overview.repairs.open &&
      state.overview.repairs.open > 0,
    isWaitingRepairs: (state, getters) =>
      state.overview &&
      state.overview.repairs &&
      state.overview.repairs.waiting &&
      state.overview.repairs.waiting > 0,
    isInboundRepairs: (state, getters) =>
      state.overview &&
      state.overview.repairs &&
      state.overview.repairs.inbound &&
      state.overview.repairs.inbound > 0,
    isOutboundRepairs: (state, getters) =>
      state.overview &&
      state.overview.repairs &&
      state.overview.repairs.outbound &&
      state.overview.repairs.outbound > 0,
    // api content holders
    messages: (state) => state.messages,
    tasks: (state) => state.tasks,
    settings: (state) => state.settings,
    personalisation: (state) => state.personalisation,
    user: (state) => state.user,
    overview: (state) => state.overview,
    sidebarUnfoldable: (state) => state.sidebarUnfoldable,
    sidebarVisible: (state) => state.sidebarVisible,
    isOverviewLoaded: (state) =>
      state.overview && state.overviewStatus == 'success',
    hasOverview: (state) =>
      state.overview && Object.keys(state.overview).length > 0,
    news: (state) => state.news,
    isNewsLoaded: (state) => state.newsStatus == 'success',
    hasNews: (state) => state.news && state.news.length > 0,
    isUserLoaded: (state) => state.user && state.userStatus == 'success',
    timeline: (state) => state.timeline,
    isTimelineLoaded: (state) => state.timelineStatus == 'success',
    hasTimeline: (state) => state.timeline && state.timeline.length > 0,
    // other holders
    isSettingsLoading: (state) => state.settingsStatus == 'loading',
    isSettingsLoaded: (state) =>
      state.settings && state.settingsStatus == 'success',
    isPersonalisation: (state) =>
      state.personalisation && Object.keys(state.personalisation).length > 0,
    isMessages: (state) => state.messages && state.messages.length > 0,
    statusDescription: (state) => state.statusDescription,
    isMessagesLoaded: (state) => state.messagesStatus == 'success',
    isContactLoaded: (state) => state.contactStatus == 'success',
    hasContact: (state) =>
      Object.keys(state.contact).length > 0 ? true : false,
    isHelpdeskOpen: (state) => state.contact && state.contact.helpdesk_open,
    isChatOpen: (state) => state.contact && state.contact.chat_open,
    jwt: (state) => 'Bearer ' + state.accessToken,
    // JWT decoded payload Getters
    accessRootPayload: (state) =>
      state.accessToken && jwtDecode(state.accessToken)
        ? jwtDecode(state.accessToken)
        : null,
    authStatus: (state) => state.status,
    // accessPermissionsPayload Computed Getters
    hasPermission: (state, getters) => (permission) =>
      getters.accessPermissionsPayload &&
      getters.accessPermissionsPayload.indexOf(permission) != -1
        ? true
        : false,
    hasTokenRole: (state, getters) => (role) =>
    getters.tokenRoles &&
    getters.tokenRoles.indexOf(role) != -1
      ? true
      : false,
    // accessRootPayload Raw Getters
    tokenFullname: (state, getters) =>
      getters.accessRootPayload &&
      'name' in getters.accessRootPayload &&
      getters.accessRootPayload.name != getters.accessRootPayload.email
        ? getters.accessRootPayload.name
        : getters.idCustomPayload &&
          'freshdesk_contact_name' in getters.idCustomPayload
        ? getters.idCustomPayload.freshdesk_contact_name
        : null,
    tokenEmail: (state, getters) =>
      getters.accessRootPayload && 'email' in getters.accessRootPayload
        ? getters.accessRootPayload.email
        : null,
    tokenSub: (state, getters) =>
      getters.accessRootPayload && 'sub' in getters.accessRootPayload
        ? getters.accessRootPayload.sub
        : null,
    tokenAvatar: (state, getters) =>
      getters.accessRootPayload && 'picture' in getters.accessRootPayload
        ? getters.accessRootPayload.picture
        : null,
    tokenJTI: (state, getters) =>
      getters.accessRootPayload && 'jti' in getters.accessRootPayload
        ? getters.accessRootPayload.jti
        : null,
    tokenExpiryDate: (state, getters) =>
      getters.accessRootPayload && 'exp' in getters.accessRootPayload
        ? new Date(getters.accessRootPayload.exp * 1000)
        : null,
    tokenNotExpired: (state, getters) =>
      getters.accessRootPayload &&
      'exp' in getters.accessRootPayload &&
      getters.accessRootPayload.exp - Date.now().valueOf() / 1000 > 0
        ? true
        : false,
    tokenRoles: (state, getters) =>
        getters.accessRootPayload && 'roles' in getters.accessRootPayload
          ? getters.accessRootPayload.roles
          : null,
    // computed Getters
    userFullname: (state, getters) =>
      (getters.userName ? getters.userName : getters.tokenFullname) ?? null,
    userFirstname: (state, getters) =>
      getters.userFullname
        ? getters.userFullname.split(' ').slice(0, -1).join(' ')
        : null,
    userLastname: (state, getters) =>
      getters.userFullname
        ? getters.userFullname.split(' ').slice(-1).join(' ')
        : null,
    tokenExpiry: (state, getters) =>
      moment(getters.tokenExpiryDate).format('MMMM D YY, h:mm:ss a'),
    // user Raw Getters
    userName: (getters) =>
      getters.user && 'name' in getters.user ? getters.user.name : null,
    userCompanyName: (getters) =>
      getters.user && 'company_name' in getters.user
        ? getters.user.company_name
        : null,
    userCompanyID: (getters) =>
      getters.user && 'company_id' in getters.user
        ? getters.user.company_id
        : null,
    userForms: (getters) =>
      getters.user && 'forms' in getters.user ? getters.user.forms : null,
    userBranding: (getters) =>
      getters.user &&
      'personalisations' in getters.user &&
      'branding' in getters.user.personalisations
        ? getters.user.personalisations.branding
        : null,
    userCompanyAdmin: (state, getters) =>
      getters.idCustomPayload &&
      'freshdesk_company_admin' in getters.idCustomPayload
        ? getters.idCustomPayload.freshdesk_company_admin
        : false,
    userPermissionsPayload: (state, getters) =>
      getters.user && 'permissions' in getters.user
        ? getters.user.permissions
        : null,
    userPersonalisationsPayload: (state, getters) =>
      getters.user && 'personalisations' in getters.user
        ? getters.user.personalisations
        : null,
    hasUserAvatar: (state, getters) =>
      getters.userAvatar != null ? true : false,
    hasUserCompany: (state, getters) =>
      getters.userCompanyName != null ? true : false,
    // ease of use permissions
    permissionIsCompanyAdmin: (state, getters) =>
      getters.userPermissionsPayload &&
      'is_company_admin' in getters.userPermissionsPayload
        ? getters.userPermissionsPayload.is_company_admin
        : false,
    permissionCanViewCompanyTickets: (state, getters) =>
      getters.userPermissionsPayload &&
      'view_company_tickets' in getters.userPermissionsPayload
        ? getters.userPermissionsPayload.view_company_tickets
        : false,
    permissionCanProvideApprovals: (state, getters) =>
      getters.userPermissionsPayload &&
      'can_provide_approvals' in getters.userPermissionsPayload
        ? getters.userPermissionsPayload.can_provide_approvals
        : false,
    permissionHasHelpdesk: (state, getters) =>
      getters.userPermissionsPayload &&
      'has_helpdesk_access' in getters.userPermissionsPayload
        ? getters.userPermissionsPayload.has_helpdesk_access
        : false,
    permissionHasServiceRequests: (state, getters) =>
      getters.userPermissionsPayload &&
      'has_service_request_access' in getters.userPermissionsPayload
        ? getters.userPermissionsPayload.has_service_request_access
        : false,
    // personalisation and branding
    hasScheduledVisits: (state, getters) =>
      getters.userPersonalisationsPayload &&
      'has_scheduled_visits' in getters.userPersonalisationsPayload
        ? getters.userPersonalisationsPayload.has_scheduled_visits
        : false,
    hasLocations: (state, getters) =>
      getters.userPersonalisationsPayload &&
      'has_locations' in getters.userPersonalisationsPayload
        ? getters.userPersonalisationsPayload.has_locations
        : false,
    locations: (state, getters) =>
      getters.userPersonalisationsPayload &&
      'locations' in getters.userPersonalisationsPayload
        ? getters.userPersonalisationsPayload.locations
        : [],
    // assist frame
    isMacFrame: (state) => (state.assistFrame.includes('macOS') ? true : false),
    isApplePrivateRelay: (state, getters) => (getters.tokenEmail && getters.tokenEmail.includes('@privaterelay.appleid.com') ? true : false),


  },
})
