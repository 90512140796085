import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/store.js'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Splash = () => import('@/views/Splash')
const SupportTickets = () => import('@/views/SupportTickets')
const NewSupportTicket = () => import('@/views/NewSupportTicket')
const NewServiceRequest = () => import('@/views/NewServiceRequest')
const Settings = () => import('@/views/Settings')
const Feedback = () => import('@/views/Feedback')
const Embed = () => import('@/views/Embed')

// Admin Views
const Admin = () => import('@/views/admin/Admin')

const TicketMaster = () => import('@/views/TicketMaster')
const TicketDetail = () => import('@/views/TicketDetail')
const RepairDetail = () => import('@/views/RepairDetail')

const NewsDetail = () => import('@/views/NewsDetail')

const Redirect = () => import('@/views/pages/Redirect')
const Register = () => import('@/views/pages/Register')
const Callback = () => import('@/views/pages/Callback')
const About = () => import('@/views/pages/About')
const Acknowledgements = () => import('@/views/pages/Acknowledgements')
const Multifactor = () => import('@/views/pages/Multifactor')
const Verify = () => import('@/views/pages/Verify')
const Login = () => import('@/views/pages/Login')
const Welcome = () => import('@/views/pages/Welcome')

const SRHelp = () => import('@/views/SRHelp')

// Vue.use(Router)

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'open active',
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: '/help',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: '/redirect',
          name: 'Redirect',
          component: Redirect,
        },
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: () => import('@/views/Dashboard.vue'),
          props: true,
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: '/help',
          name: 'Get Support',
          component: () => import('@/views/Splash.vue'),
          props: true,
          meta: {
            requiresAuth: false,
          },
        },
        // {
        //   path: '/settings',
        //   name: 'Settings',
        //   component: () => import('@/views/Settings.vue'),
        //   props: true,
        //   meta: {
        //     requiresAuth: true,
        //   },
        // },
        // {
        //   path: '/feedback',
        //   name: 'Feedback',
        //   component: () => import('@/views/Feedback.vue'),
        //   props: true,
        //   meta: {
        //     requiresAuth: false,
        //   },
        // },
        {
          path: 'content',
          name: 'Content',
          component: Embed,
          props: true,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/news',
          name: 'News',
          redirect: '/dashboard',
          children: [
            {
              path: 'item/:id',
              name: 'News Item',
              component: NewsDetail,
              meta: {
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: '/support-tickets',
          name: 'Support Tickets',
          component: {
            render() {
              return h(resolveComponent('router-view'))
            },
          },
          redirect: '/support-tickets/list',
          children: [
            {
              path: '/support-tickets/list/:filter?',
              name: 'Ticket List',
              component: TicketMaster,
              props: { ticketType: 'support-tickets' },
              meta: {
                requiresAuth: true,
                breadcrumbName: 'List of Support Tickets',
                breadcrumbPath: '/repairs/list/',
              },
            },
            {
              path: 'detail/:id',
              name: 'Support Ticket Details',
              component: TicketDetail,
              props: true,
              meta: {
                requiresAuth: true,
                breadcrumbPath: '/support-tickets/list/',
              },
            },
            {
              path: '/support-tickets/new',
              name: 'New Support Ticket',
              component: () => import('@/views/NewSupportTicket.vue'),
              meta: {
                requiresAuth: false,
              },
            },
            {
              path: 'srhelp',
              name: 'SRHelp',
              component: SRHelp,
              meta: {
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: '/repairs',
          name: 'Repairs',
          component: {
            render() {
              return h(resolveComponent('router-view'))
            },
          },
          redirect: '/dashboard',
          children: [
            {
              path: '/repairs/list/:filter?',
              name: 'Repair List',
              component: () => import('@/views/TicketMaster.vue'),
              props: { ticketType: 'repairs' },
              meta: {
                requiresAuth: true,
                breadcrumbName: 'List of Repairs',
                breadcrumbPath: '/repairs/list/',
              },
            },
            {
              path: '/repairs/detail/:id',
              name: 'Repair Details',
              component: RepairDetail,
              props: true,
              meta: {
                requiresAuth: true,
                breadcrumbPath: '/repairs/list/',
              },
            },
            {
              path: 'new',
              name: 'New Repair',
              component: NewServiceRequest,
              meta: {
                requiresAuth: false,
              },
            },
          ],
        },
      ],
    },
    {
      path: '/auth',
      redirect: '/help',
      name: 'Auth',
      component: {
        render() {
          return h(resolveComponent('router-view'))
        },
      },
      children: [
        {
          path: '/auth/callback',
          name: 'Callback',
          component: Callback,
        },
        {
          path: '/auth/multifactor',
          name: 'Multifactor',
          component: () => import('@/views/pages/Multifactor.vue'),
        },
        {
          path: '/auth/verify',
          name: 'Verify',
          component: () => import('@/views/pages/Verify.vue'),
        },
        {
          path: '/auth/login',
          name: 'Login',
          component: Login,
        },
      ],
    },
    {
      path: '/welcome',
      name: 'Welcome',
      component: Welcome,
    },
    {
      path: '/about',
      redirect: '/about/assist',
      name: 'About',
      component: {
        render(c) {
          return h(resolveComponent('router-view'))
        },
      },
      children: [
        {
          path: '/about/assist',
          name: 'About Assist',
          component: () => import('@/views/pages/About.vue'),
        },
        {
          path: '/about/acknowledgements',
          name: 'Acknowledgements',
          component: () => import('@/views/pages/Acknowledgements.vue'),
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn && store.getters.tokenNotExpired) {
      next()
      return
    } else {
      // not authed or token expired
      console.log('[Assist] Need login to access auth route: ' + to.path)
      store.dispatch('login', { path: to.path })
    }
  } else if (to.matched.some((record) => record.meta.requiresPermission)) {
    if (store.getters.hasPermission(to.meta.requiresPermission)) {
      next()
      return
    }
    next(false)
  } else {
    next()
  }
})

export default router
