import {
  cibSkype,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex,
  cibAuth0,
  cibMicrosoft,
  cibApple,
  cibGoogle,
  cilQrCode,
  cilDevices,
  cilBarcode,
  cilFilterX,
} from '@coreui/icons'
import { cifUs, cifBr, cifIn, cifFr, cifEs, cifPl } from '@coreui/icons'
import {
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowRight,
  cilBan,
  cilBasket,
  cilBell,
  cilBold,
  cilBookmark,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCircle,
  cilCheckCircle,
  cilCode,
  cilCommentSquare,
  cilCreditCard,
  cilCursor,
  cilCursorMove,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilFullscreen,
  cilFullscreenExit,
  cilHome,
  cilInbox,
  cilInfo,
  cilMenu,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPrint,
  cilPuzzle,
  cilSave,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilXCircle,
  cilWarning,
  cilFingerprint,
  cilPlus
} from '@coreui/icons'
import { logo } from './logo'
import {
  bisPlus,
  bisRepair,
  bisApproval,
  bisAwaiting,
  bisIdentity,
  bisIdentityLogo,
  bisHelp,
  bisParcel,
  bidRepair,
  bisTruck,
  bisClipboard,
  bisTruckBooking,
  bisBooked,
  bicCrown,
  bicTicket,
  bicPadlock,
  bicIdentityCard,
  bicVerified,
  bicError,
  bicLifebuoy,
  bicToolbox,
  bicHandshake,
  bicPassword,
  bicPersonal,
  bicPhone,
  bicWelcome,
  bicExit,
  bisStop,
  bicStamp,
  bisDotGreen,
  bisDotOrange,
  bisDotRed,
  bisDotBlue
} from './custom'

import {
  cisHeart,
  cisArrowThickLeft,
  cisStarCircle,
  cisPlus,
  cisSettings,
  cisCalendar,
  cidSecurity,
  cisKey,
  cidLifeRing,
  cisCommentBubbleQuestion,
  cisTruck,
  cisBank,
  cisSearch,
  cisWarning,
  cisCheck,
  cisCheckCircle,
  cilHourglass,
  cisBox,
  cisChevronDoubleDown,
  cisChevronLeft,
  cisChevronRight,
  cisFile,
  cisPencil,
  cisSpeechNotes,
  cilPlusCircle,
  cisLevelDown,
  cisImage,
  cisTag,
  cisList,
  cisFilter,
  cisListNumbered,
  cisPlusCircle,
  cilSpeechNotes,
  cidToll,
  cisCircle,
  cisCloudDownload,
  cilHandshake,
  cidUser,
  cisMail,
  cisChevronSquareRightAlt,
  cisCog,
  cisChevronDoubleUp,
  cisChevronTop,
  cisChevronBottom,
  cisMinus,
  cisUser,
  cisPeople,
  cidPeople,
  cisChevronSquareRight,
  cisX,
  cidBuilding,
  cidVote,
  cisDollar,
  cisNote,
  cisTrash,
  cisDevices,
  cilGlobeAltLock,
  cisClock,
  cisShieldPlus,
  cisLibraryBooks,
  cisPaperclip,
  cidChatBubble,
  cidLibraryAdd,
  cisLifeRing,
  cisLockLocked,
  cisBug,
  cidNewReleases,
  cisIdBadge,
  cidSpeechNotes,
  cisCaretBottom,
  cidFingerprint,
  cidChartTable,
  cidClock,
  cidBuildingBusiness,
  cidDollarCircle,
  cidFolder,
  cidRecycle,
  cidArchive,
  cisMediaRecord,
  cisFingerprint,
  cilChevronRightAlt,
  cidHeadphones,
  cidLaptop,
  cidTablet,
  cidMobile,
  cidInboxOut,
  cidInboxIn,
  cidStarCircle,
  cidChatSquare,
  cidDescription,
  cisDescription,
  cidLibraryBooks,
  cidTag,
} from '@coreui/icons-pro'

export const iconsSet = Object.assign(
  {},
  { logo },
  {
    bisRepair,
    bisApproval,
    bisAwaiting,
    bisIdentity,
    bisHelp,
    bisParcel,
    bidRepair,
    bisTruck,
    bisClipboard,
    bisTruckBooking,
    bisBooked,
    bicCrown,
    bicTicket,
    bicPadlock,
    bicIdentityCard,
    bisIdentityLogo,
    bicVerified,
    bicError,
    bicLifebuoy,
    bicToolbox,
    bicHandshake,
    bicPassword,
    bicPersonal,
    bicPhone,
    bicWelcome,
    bicExit,
    bisStop,
    bicStamp,
    bisDotGreen,
    bisDotOrange,
    bisDotRed,
    bisDotBlue,
    bisPlus
  },
  {
    cilAlignCenter,
    cilAlignLeft,
    cilAlignRight,
    cilApplicationsSettings,
    cilArrowRight,
    cilBan,
    cilBasket,
    cilBell,
    cilBold,
    cilBookmark,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronLeft,
    cilChevronRight,
    cilChevronTop,
    cilCircle,
    cilCheckCircle,
    cilCode,
    cilCommentSquare,
    cilCreditCard,
    cilCursor,
    cilCursorMove,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeLetter,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilFullscreen,
    cilFullscreenExit,
    cilHome,
    cilHourglass,
    cilInbox,
    cilIndentDecrease,
    cilIndentIncrease,
    cilInputPower,
    cilItalic,
    cilInfo,
    cilJustifyCenter,
    cilJustifyLeft,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilListNumbered,
    cilListRich,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMap,
    cilMoon,
    cilNotes,
    cilOptions,
    cilPaperclip,
    cilPaperPlane,
    cilPencil,
    cilPeople,
    cilPhone,
    cilPrint,
    cilPuzzle,
    cilSave,
    cilSettings,
    cilShare,
    cilShareAll,
    cilShareBoxed,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilSpreadsheet,
    cilStar,
    cilSun,
    cilTags,
    cilTask,
    cilTrash,
    cilUnderline,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilUserUnfollow,
    cilXCircle,
    cilWarning,
    cilPlusCircle,
    cilSpeechNotes,
    cidSpeechNotes,
    cidInboxOut,
    cidInboxIn,
    cidStarCircle,
    cidChatSquare,
    cidDescription,
    cisDescription,
    cilFilterX
  },
  {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl,
  },
  {
    cibSkype,
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibStripe,
    cibPaypal,
    cibGooglePay,
    cibCcAmex,
    cibAuth0,
    cibMicrosoft,
    cibApple,
    cibGoogle,
    cilQrCode,
    cilDevices,
    cilBarcode,
    cidLibraryBooks,
    cidTag
  },
  {
    cisHeart,
    cisArrowThickLeft,
    cisStarCircle,
    cisPlus,
    cisSettings,
    cisCalendar,
    cidSecurity,
    cisKey,
    cidLifeRing,
    cisCommentBubbleQuestion,
    cisTruck,
    cisBank,
    cisSearch,
    cisWarning,
    cisCheck,
    cisCheckCircle,
    cisBox,
    cisChevronDoubleDown,
    cisChevronLeft,
    cisChevronRight,
    cisFile,
    cisPencil,
    cisSpeechNotes,
    cisChevronSquareRightAlt,
    cisChevronSquareRight,
    cisCog,
    cisLevelDown,
    cisImage,
    cisTag,
    cisList,
    cisFilter,
    cisListNumbered,
    cisPlusCircle,
    cidToll,
    cisCircle,
    cisCloudDownload,
    cilHandshake,
    cidUser,
    cisMail,
    cisChevronDoubleUp,
    cisChevronTop,
    cisChevronBottom,
    cisMinus,
    cisUser,
    cisPeople,
    cidPeople,
    cisX,
    cidBuilding,
    cidVote,
    cisDollar,
    cisNote,
    cisTrash,
    cisDevices,
    cilGlobeAltLock,
    cisClock,
    cisShieldPlus,
    cisLibraryBooks,
    cisPaperclip,
    cidChatBubble,
    cidLibraryAdd,
    cisLifeRing,
    cisLockLocked,
    cisBug,
    cidNewReleases,
    cisIdBadge,
    cisCaretBottom,
    cilMenu,
    cidFingerprint,
    cilFingerprint,
    cisFingerprint,
    cilPlus,
    cidChartTable,
    cidClock,
    cidBuildingBusiness,
    cidRecycle,
    cidDollarCircle,
    cidFolder,
    cidArchive,
    cisMediaRecord,
    cilChevronRightAlt,
    cidHeadphones,
    cidLaptop,
    cidTablet,
    cidMobile
  },
)
